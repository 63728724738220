/** @jsx jsx */
import { jsx, Themed } from "theme-ui";

export default function Organizers() {
  return (
    <div sx={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
      <a href="https://www.maulina.cl/" target="_blank" rel="noopener noreferrer" sx={{ display: "flex" }}>
        <img alt="Maulina" src="/maulina.svg" sx={{ m: "auto", width: "100%", maxWidth: 120 }} />
      </a>
      <Themed.h1>&</Themed.h1>
      <a href="https://www.casamaleza.cl/" target="_blank" rel="noopener noreferrer" sx={{ display: "flex" }}>
        <img alt="Maleza" src="/maleza.svg" sx={{ m: "auto", width: "100%", maxWidth: 120 }} />
      </a>
    </div>
  );
}
