/** @jsx jsx */
import { Card, jsx, Spinner, Themed } from "theme-ui";
import { useEffect, useState } from "react";
import { Link } from "gatsby";

import Layout from "../../components/layout";
import Organizers from "../../components/organizers";
import TransactionDetails from "../../components/transactionDetails";
import Seo from "../../components/seo";

export default function StatusPage({ params: { buyOrder } }) {
  const [data, setData] = useState("loading");
  useEffect(() => {
    const getStatus = async () => {
      const response = await fetch("/api/status", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ buyOrder }),
      });
      const { data } = await response.json();
      setData(data);
    };
    getStatus();
  }, [buyOrder]);
  return (
    <Layout>
      {data === "loading" ? (
        <Spinner sx={{ m: "auto" }} />
      ) : data?.response_code === 0 && data?.status === "AUTHORIZED" ? (
        <Card sx={{ m: "auto", maxWidth: "container" }}>
          <div sx={{ textAlign: "center" }}>
            <Themed.h1>Genial {data.name} 📽️</Themed.h1>
            <Themed.p>
              Recibimos tu pago sin problemas. Ya va en camino tu código al correo{" "}
              <strong sx={{ color: "primary" }}>{data.email}</strong>.<br />
              Esperamos que disfrutes el encuentro.
            </Themed.p>
            <TransactionDetails {...data} />
          </div>
          <Themed.h2 sx={{ textAlign: "center", mb: -2 }}>Muchas gracias en nombre de</Themed.h2>
          <Organizers />
        </Card>
      ) : (
        <Card sx={{ m: "auto", maxWidth: "container" }}>
          <div sx={{ textAlign: "center" }}>
            <Themed.h1>Hmmm 🤔</Themed.h1>
            <Themed.p>
              Hubo un problema.
              <br />
              No te preocupes, no se hizo ningún cargo a tu tarjeta.
              <br />
              Si gustas puedes{" "}
              <Link to="/" sx={(t) => t.styles.a}>
                volver al inicio
              </Link>{" "}
              e intentarlo nuevamente .
            </Themed.p>
            {/* <TransactionDetails {...data} /> */}
          </div>
        </Card>
      )}
    </Layout>
  );
}

export const Head = () => (
  <Seo>
    <meta name="robots" content="noindex, nofollow" />
  </Seo>
);
