/** @jsx jsx */
import React from "react";
import { jsx, Themed } from "theme-ui";

export default function TransactionDetails({
  buy_order,
  amount,
  authorization_code,
  payment_type_code,
  installments_number,
  installments_amount,
  card_detail,
  product,
  transaction_date,
}) {
  const formatDate = (transaction_date) =>
    new Date(transaction_date).toLocaleDateString("es", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });

  return (
    <React.Fragment>
      <Themed.h4 sx={{ textAlign: "center", mb: 0 }}>Detalles de la transacción</Themed.h4>
      {[
        buy_order ? { key: "Número de orden", value: buy_order } : null,
        amount ? { key: "Monto", value: "CLP$" + amount } : null,
        authorization_code ? { key: "Código de autorización", value: authorization_code } : null,
        transaction_date ? { key: "Fecha", value: formatDate(transaction_date) } : null,
        payment_type_code ? { key: "Tipo de pago", value: payment_type_codes[payment_type_code] } : null,
        // Tipo de cuota
        installments_number ? { key: "Cantidad de cuotas", value: installments_number } : null,
        installments_amount ? { key: "Monto de cuota", value: "CLP$" + installments_amount } : null,
        card_detail?.card_number ? { key: "Tarjeta bancaria", value: "***" + card_detail?.card_number } : null,
        product ? { key: "Detalle", value: product } : null,
      ]
        .filter((el) => el)
        .map(({ key, value }) => (
          <div key={key} sx={{ display: "flex", justifyContent: "space-between" }}>
            <Themed.p sx={{ fontSize: 1, my: 1 }}>{key}</Themed.p>
            <Themed.p sx={{ fontSize: 1, my: 1 }}>
              <strong>{value}</strong>
            </Themed.p>
          </div>
        ))}
    </React.Fragment>
  );
}

const payment_type_codes = {
  VD: "Venta Débito",
  VN: "Venta Normal",
  VC: "Venta en cuotas",
  SI: "3 cuotas sin interés",
  S2: "2 cuotas sin interés",
  NC: "N Cuotas sin interés",
  VP: "Venta Prepago",
};
